import * as React from "react";
import Page from "../containers/Page";
import Hero from "../components/Hero";
import Stripes from "../images/stripes.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../style/custom-style.css";

// markup
const OverFlexoffice = () => {
  return (
    <Page>
      <Hero small={true}>
        <h1>
          Hoe pakt <br />
          FlexOffice het aan?
        </h1>
      </Hero>
      <div className="container-fluid">
        <div className="container">
          <section className="mt-5">
            <div className="stripes">
              <img src={Stripes} alt="" />
            </div>

            <div className="intro">
              <p>
                Prima, we gaan er voor! Maar hoe regelen we het nu praktisch?
                Hoe gaan we te werk? FlexOffice is flexibel. Alles is
                bespreekbaar en samen maken we een contract op, op maat van uw
                bedrijf. Van aantal uren tot de werkplek en het samengestelde
                takenpakket.{" "}
              </p>
            </div>
          </section>
        </div>
        <section className="mt-5 line-bg">
          <div className="container">
            <div className="row">
              <div className="col-sm-10">
                <h2>Praktische info</h2>
                <small>Volledig op maat samen te stellen</small>
              </div>
            </div>

            <div className="mt-4">
              <div className="row">
                <div className="col-sm-10">
                  <h4>Takenpakket</h4>
                  <p>Het takenpakket wordt in overleg samengesteld.</p>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="row">
                <div className="col-sm-10">
                  <h4>Periodiciteit</h4>
                  <p>
                    Dit kan van enkele uren per maand tot enkele dagen per week
                    gaan. Verkies je een vast uurrooster of is flexibel ook
                    goed? Alles valt te bespreken. We bekijken samen waar ik
                    voor jou van dienst zou kunnen zijn en stellen een pakket op
                    maat op.
                  </p>
                  <p>
                    Ook tijdelijke vervangingen of éénmalige opdrachten zijn
                    mogelijk.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="row">
                <div className="col-sm-10">
                  <h4>Hoe?</h4>
                  <p>
                    We starten met een gratis kennismakingsgesprek
                    <br />
                    We stellen een takenpakket en uurregeling vast
                    <br />
                    We komen samen tot een overeenkomst
                    <br />
                    De facturatie verloopt maandelijks met een duidelijk
                    overzicht van de gepresteerde uren en taken.
                    <br />
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="row">
                <div className="col-sm-10">
                  <h4>Waar?</h4>
                  <p>
                    In overleg ben ik daar zeer flexibel in.
                    <br />
                    - Bij u op kantoor <br />
                    - Vanop mijn thuiskantoor <br />
                    - Combinatie van beide <br />
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-4 mb-5">
              <div className="row">
                <div className="col-sm-10">
                  <h4>In welke regio?</h4>
                  <p>
                    Prioritair in Merelbeke, Melle, Oosterzele, Zwijnaarde,
                    Sint-Lievens-Houtem.
                    <br />
                    Daarnaast volledig Oost-Vlaanderen
                    <br />
                    Daarbuiten vanop afstand ook zeker mogelijk
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};

export default OverFlexoffice;
