import * as React from "react";
import Logo from "../images/logo.svg";
import LinkedInIcon from "../images/linkedin.svg";
import { Link } from "gatsby";

// markup
const Page = (props) => {
  const [navOpen, setNavOpen] = React.useState(true);
  const [sendDetails, setSendDetails] = React.useState({
    show: false,
    succes: false,
    message: "",
  });

  const sendEmail = () => {
    const form = document.getElementById("contactForm");
    const formData = new FormData(form);
    if (formData.get("email") !== "" && formData.get("bericht") !== "") {
      fetch("sendEmail.php", {
        method: "POST", // or 'PUT'
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            setSendDetails({
              show: true,
              succes: false,
              message: "Fout: uw bericht kon niet worden verstuurd.",
            });
            return response;
          } else {
            return response.json();
          }
        })
        .then((response) => {
          if (response.send) {
            form.reset();
            setSendDetails({
              show: true,
              succes: true,
              message: "Uw bericht werd succesvol verzonden.",
            });
            setTimeout(() => {
              setSendDetails({
                show: false,
                succes: false,
                message: "",
              });
            }, 4000);
          } else {
            setSendDetails({
              show: true,
              succes: false,
              message:
                "Uw bericht werd verstuurd, maar de e-mail kon niet verzonden worden.",
            });
          }
        })
        .catch((error) => {
          setSendDetails({
            show: true,
            succes: false,
            message: "Fout: uw bericht kon niet worden verstuurd.",
          });
        });
    } else {
      setSendDetails({
        show: true,
        succes: false,
        message: "Gelieve alle velden in te vullen.",
      });
    }
  };

  return (
    <div>
      <header>
        <nav className="navbar shadow-sm navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setNavOpen(!navOpen)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="">
              <Link to="/">
                <img src={Logo} alt="FlexOffice logo" width="200px" />
              </Link>
            </div>

            <div
              className={` ${
                navOpen ? "collapse " : ""
              }  navbar-collapse justify-content-end`}
            >
              <ul className="navbar-nav  mb-2 mb-lg-0">
                <li className="nav-item fw-bolder">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/over-flexoffice"
                  >
                    over ons
                  </Link>
                </li>
                <li className="nav-item fw-bolder">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/services"
                  >
                    services
                  </Link>
                </li>
                <li className="nav-item fw-bolder">
                  <Link
                    className="nav-link"
                    activeClassName="active"
                    to="/praktische-info"
                  >
                    praktische info
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {props.children}
      <footer className="bg-dark-blue p-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <h2 className="text-light">Contact</h2>
              <br />
              <p>
                FlexOffice
                <br />
                Smeyersveld 4<br />
                9860 Oosterzele
                <br />
                Tel: +32 480 62 38 01
                <br />
                E:{" "}
                <a href="mailto:anke@mf-flexoffice.be" className="text-light">
                  anke[at]mf-flexoffice.be
                </a>
                <br />
                BTW: BE0743 638 127
                <br />
                <br />
                <a
                  href="https://www.linkedin.com/in/make-f-it-flexoffice-282130215/"
                  target="_blank"
                  rel="noreferrer"
                  title="Link naar FlexOffice linkedin pagina"
                >
                  <img
                    src={LinkedInIcon}
                    width="43"
                    height="43"
                    alt="LinkedIn icoon"
                  />
                </a>
              </p>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-5">
              <h2 className="text-light">Contact</h2>
              <br />
              <form id="contactForm" action="sendEmail.php" method="POST">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    E-mailadres
                  </label>
                  <input
                    type="email"
                    className="form-control form-input"
                    id="email"
                    name="email"
                    required
                    placeholder="name@example.com"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="bericht" className="form-label">
                    Bericht
                  </label>
                  <textarea
                    className="form-control form-input"
                    id="bericht"
                    name="bericht"
                    required
                    rows="10"
                  ></textarea>
                </div>
                <div className="mb-3" style={{ display: "none" }}>
                  <label htmlFor="Bedrijf" className="form-label">
                    Bedrijf
                  </label>
                  <input
                    className="form-control form-input"
                    id="bedrijf"
                    name="bedrijf"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="button"
                    name="submit"
                    value="Verzenden"
                    className="btn form-button-outline mb-3 px-5"
                    onClick={() => sendEmail()}
                  />
                  {sendDetails.show && (
                    <div
                      class={`alert ${
                        sendDetails.succes ? "alert-success" : "alert-danger"
                      }`}
                      role="alert"
                    >
                      {sendDetails.message}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </footer>
      <div className="d-flex justify-content-center align-items-end p-3">
        <p className="fw-bold m-0">FLEXOFFICE - 2021</p>
      </div>
    </div>
  );
};

export default Page;
