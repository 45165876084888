import React from "react";
import "./style.css";

const Hero = (props) => {
  return (
    <div className={`hero ${props.small ? "small" : ""}`}>
      <div className="container">
        <div>{props.children}</div>
      </div>
    </div>
  );
};
export default Hero;
